import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading } from "../bruin"

const AboutPage = () => (
	<Layout>
		<SEO title="Home" />
		<Heading>About Grayhaber Page Works</Heading>
	</Layout>
)

export default AboutPage
